.header_outer_container{
position:sticky;
top: 0;
display: flex;
justify-content: center;
align-items: center;
background-color: rgb(20 20 20);
background: transparent;
height: 10vh;
color: white;
transition: all 0.5s;
transition-timing-function: ease-in;
z-index: 1;
/* height: auto; */
min-height: 70px;
}

.kids{
  font-size: medium;
  font-weight: 500;
}
/* .nav_black{
    background-color: rgb(20 20 20);
} */
.header-container{
    width: 90%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* color: white; */
}

.header-left{
  font-size: medium
}
.header-right{
  font-size: medium
}
.header-left ul{
  display: flex;
  justify-content: space-between;
  align-items: center;
  list-style-type: none;
  cursor: pointer;
}
.header-right ul{
  display: flex;
  justify-content: space-between;
  align-items: center;
  list-style-type: none;
}
.header-left ul li{
  margin: 10px;
}

.header-right ul li{
  margin: 10px;
  font-size: 5px;
}


a {
    color: white;
     text-decoration: none; /* Optional: remove underline */
  }

/* Style for the link when hovered over */
a:hover {
          color: white;
        }
    
/* Style for the link when it has been visited */
a:visited {
            color: white;
        }

/* Style for the link when it is active (being clicked) */
a:active {
          color: white;
        }

@media screen and (max-width:720px){
    .header_outer_container{
        display: flex;
        flex-direction: column !important;
        height: auto;
    }
}