.banner{
    /* margin-top: -10vh; */
    height: 80vh;
    /* margin-bottom: -80vh; */
    color: white;
    object-fit: contain;
}
.banner_contents{
    margin-left: 30px;
    /* margin-top: 300px; */
    height: 190px;
}
.banner_title{
    font-size: 3rem;
    font-weight: 800;
    padding-bottom: 0.3rem;
    /* margin-top: 160px; */
    padding-top: 160px;
}
.banner_description{
    width: 45rem;
    line-height: 1;
    padding: 1rem 0;
    font-size: 1rem;
    max-width: 360px;
    height: 80px;
}
.banner_button{
    cursor: pointer;
    color: #fff;
    outline: none;
    border: none;
    font-size: 1.2rem;
    font-weight: 700;
    border-radius: 0.2vw;
    padding: 1rem 3rem;
    margin-right: 2rem;
    background-color: rgba(51, 51, 51, 0.5);
    padding-bottom: 10px;
    /* margin-top: 160px; */
}
.play{
    color: #000;
    background-color: #fff;
}
.banner_button:hoover{
    color: #000;
    background-color: #c6c6c6;
    transition: all0.2s;
}
.banner_fadeBottom{
    height: 100vh;
    background-image: linear-gradient( 
        180deg, 
        transparent, 
        rgb(37,37,37,0.91), 
        #111);
}
