@import url('https://fonts.googleapis.com/css?family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Lexend:wght@100;200;300;400;500;600;700;800;900&display=swap');

:root {
  --font-base: 'Lexend', sans-serif;
  --font-second-base: 'Inter', sans-serif;
  --bg-color: black;
  --primary-color: white;
  --secondary-color: red;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body{
  background: black;
}
