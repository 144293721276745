.footer_outer_container{
    margin-top: 20px;
    margin-left: 70px;
    background-color: black;
    display: flex;
    justify-content: center;
    color:gray;
}
.footer_inner_container{
    width: 60%;
    /* background-color: red */

}
.footer_icons{
    width: 12%;
    display: flex;
    justify-content: space-between;
    color: white;
}
.footer_data{
    display: flex;
    justify-content: space-between;
}
.footer_data ul{
    list-style: none;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: 12vh;
    /* background-color: blue; */
}
.footer_data ul li{
    cursor: pointer;
}
.service_code{
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: white 1px solid;
    max-width: 100%;
    font-size: 14px;   
    width: 10%;
    margin: 10px 0;
    cursor: pointer;
}
.copy-write{
    font-size: 12px;
    margin-bottom: 10px;
}